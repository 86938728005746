const initialState = {
    filter_id : 0,
    filterCriteria : {},
    deviceList : [],
    size : 0,
    start : 0,
    end : 10,
    pageNumber : 1,
    sortOrder : null,
    sortColumn : null,
    isEdit : false,
    message : null,
    deviceTypes : [],
    deciveGroups : [],
    nextToken : null,
    deviceCount : 0,
    isRun : false,
    filterNameErrorMsg: null,
    isValidFilterName : true,
    ecuList:[],
    orgDeviceCount:0
}

export default function reducer(state = initialState , action) {
    switch(action.type) {
        case 'LOAD_DEVICES_SUCCESS':
            const data = action.payload.data.data.data[0];
            const devices = state.deviceList.concat(data.devices);
            return {
                ...state,
                deviceList : devices,
                size : devices.length,
                deviceCount : data.devices_count,
                filterCriteria : { 
                    "device_attributes" : data.device_attributes, 
                    "filter_name" : data.filter_name, 
                    "public_filter" : data.public_filter, 
                    "device_names_included_pattern" : data.device_names_included_pattern, 
                    "device_types" : data.device_types, 
                    "device_software_version" : data.device_software_version, 
                    "device_groups" : data.device_groups,
                    "device_connectivity_attributes" : data.device_connectivity_attributes,
                    "device_names" : data.device_names,
                    "ecu_versions" : data.ecu_versions
                },
                filter_id : data.filter_id,
                isEdit : action.payload.isEdit,
                message : null,
                nextToken : data.next_token,
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
            }
        case 'SAVE_CRITERIA_SUCCESS' :
            return { 
                ...state,
                filterID : action.payload.data.data.filterID,  
                message : "Save Success" ,
                isRun : false
            }
        case 'SAVE_CRITERIA_FAILURE' : 
            return {
                ...state,
                message : action.payload.data.data.error_message
            }   
        case 'CLEAR_SAVE_MESSAGE' : 
            return {
                ...state,
                message : null
            }       
        case 'RUN_CRITERIA_SUCCESS' : {
            const deviceDetailArray = action.payload.data.data.data[0];
            const devices = state.deviceList.concat(deviceDetailArray.devices);
            return { 
                ...state,
                deviceList : devices, 
                size : devices.length,
                deviceCount : deviceDetailArray.devices_count, 
                filterCriteria : { 
                    "device_attributes" :deviceDetailArray.device_attributes, 
                    "filter_name" : deviceDetailArray.filter_name, 
                    "public_filter" : deviceDetailArray.public_filter, 
                    "device_names_included_pattern" : deviceDetailArray.device_names_included_pattern, 
                    "device_types" : deviceDetailArray.device_types, 
                    "device_software_version" : deviceDetailArray.device_software_version, 
                    "device_groups" : deviceDetailArray.device_groups,
                    "device_connectivity_attributes" : deviceDetailArray.device_connectivity_attributes
                },
                nextToken : deviceDetailArray.next_token,
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
                message : null, 
                isRun : true
            }
        }
        case 'CLEAR_DEVICE_INFORMATION' : {
            return {
                ...state,
                deviceList : [],
                isEdit : false,
                message : null,
                deviceTypes : [],
                deciveGroups : [],
                size : 0,
                deviceCount : 0,
                start : 0,
                end : 10,
                pageNumber : 1,
                filter_id : 0,
                isRun : false,
                filterCriteria : {},
            }
        }
        case 'CLEAR_DEVICES' : {
            return {
                ...state,
                deviceList : [],
                size : 0,
                deviceCount : 0,
                start : 0,
                end : 10,
                pageNumber : 1,
                message : null
            }
        }
        case 'LOAD_NEXT_PAGE' : {
            return {
                ...state,
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
                message : null
            }
        }
        case 'FILTER_NAME_VALIDATION_FAILURE' : {
            return {
                ...state,
                filterNameErrorMsg : action.payload.data.data.error_message,
                isValidFilterName : false
            }
        }
        case 'FILTER_NAME_VALIDATION_SUCCESS' : {
            return {
                ...state,
                filterNameErrorMsg : null,
                isValidFilterName : true
            }
        }
        case "FETCH_DEVICE_TYPES_SUCCESS" : 
            return {
                ...state,
                deviceTypes : action.payload.data.data,
            }
        case "FETCH_DEVICE_TYPES_FAILURE" : {
            return { ...state, deviceTypes : []}
        }
        case "FETCH_DEVICE_GROUPS_SUCCESS" : {
            return { ...state, deviceGroups : action.payload.data.data }
        }
        case "FETCH_DEVICE_GROUPS_FAILURE" : {
            return { ...state, deviceGroups : [] } 
        }
        case "FETCH_ECU_FAILURE": {
            return {...state, error: action.payload}
        }
        case "FETCH_ECU_SUCCESS": {
            return {...state, ecuList: action.payload.data}
        }
        case "CLEAR_ECU_DATA": {
            return {...state, ecuList: []}
        }
        case "FETCH_DEVICE_COUNT_FAILURE": {
            return {...state, error: action.payload}
        }
        case "FETCH_DEVICE_COUNT_SUCCESS": {
            return {...state, orgDeviceCount: action.payload.data}
        }
    }
    return state;
}